<template>
  <section class="reward">
    <div class="reward-left">
      <div class="reward-header">
        <div>
          <div class="light-text2"> {{ $t('solana.staking.tvl') }} </div>
          <div class="light-text">$ 157M</div>
        </div>
        <div>
          <div class="light-text2">{{ $t('solana.staking.farming_apy') }}</div>
          <div class="light-text">10%</div>
        </div>

        <div class="reward-header-icon">
          <img src="@/assets/img/solana/sht.png" alt="sht">
        </div>
      </div>

      <div class="reward-left-title">
        {{ $t('solana.staking.deposit_single_token') }}
      </div>
      <div class="reward-status d-flex justify-center">
        <div class="reward-status-active">{{ $t('solana.status.active') }} </div>
      </div>

      <div class="reward-items">
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.total_pool_deposits') }}</div>
          <div class="light-text">90,325 SHT</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.total_rewards') }}</div>
          <div class="light-text">40,000 SHT</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="light-text2">{{ $t('solana.rewards_remaning') }}</div>
          <div class="light-text">28,312.22 SHT</div>
        </div>
      </div>

      <div class="reward-info">
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.time_to_deposit') }}</div>
          <div class="light-text">1d:12h:33m:12s</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.max_pool_size') }}</div>
          <div class="light-text">{{ $t('solana.unlimited') }}</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.rewards_type') }}</div>
          <div class="light-text">{{ $t('solana.single_unlocked') }}</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.pool_type') }}</div>
          <div class="light-text">{{ $t('solana.unlocked_staking') }}</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.start_date') }}</div>
          <div class="light-text">03-12-2021</div>
        </div>
        <div class="reward-info-block">
          <div class="light-text2">{{ $t('solana.end_date') }}</div>
          <div class="light-text">04-01-2022</div>
        </div>
      </div>

    </div>

    <div class="reward-right">
      <div class="reward-right-content">
        <div class="reward-right-title"> {{ $t('solana.your_stake') }} </div>

        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.staked') }}</div>
            <div>SHT</div>
          </div>
          <div>
            <div class="d-flex align-center">
              -
              <img src="@/assets/img/solana/sht.png" alt="eth">
            </div>
          </div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.rewards') }}</div>
            <div>SHT</div>
          </div>
          <div>-</div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.available_to_claim') }}</div>
            <div>SHT</div>
          </div>
          <div> - </div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.claimed') }}</div>
            <div>SHT</div>
          </div>
          <div> - </div>
        </div>
        <div class="reward-right-item d-flex align-center justify-space-between">
          <div>
            <div class="light-text2">{{ $t('solana.time_until_withdrawal') }}</div>
            <div>12d2h:33m:12s</div>
          </div>
        </div>

        <div class="reward-right-grid">
          <div
            class="reward-right-column d-flex align-center justify-center text-center active"
            @click="stake_modal = true"
          >
            <div class="light-text">{{ $t('solana.stake') }}</div>
            <div class="light-text2">SHT</div>
          </div>
          <div
            class="reward-right-column d-flex align-center justify-center text-center inactive"
            @click="claim_modal = true"
          >
            <div class="light-text">{{ $t('solana.exit_from_stake') }}</div>
            <div class="light-text2"> {{ $t('solana.withdraw_and_claim') }}</div>
          </div>
        </div>
      </div>
    </div>

    <stake-tokens-modal v-model="stake_modal"/>
    <s-claim-rewards-modal v-model="claim_modal"/>

  </section>
</template>

<script>
import StakeTokensModal from './StakeTokensModal';
import SClaimRewardsModal from './SClaimRewardsModal';

export default {
  name: 'StakeBlock',

  components: { SClaimRewardsModal, StakeTokensModal },

  data() {
    return {
      stake_modal: false,
      claim_modal: false,
    };
  },
};
</script>

<style scoped lang="scss">

</style>
