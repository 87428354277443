<template>
  <div class="solana-page">
    <div class="solana-page-top">
      <div class="solana-pool-switch">

        <div class="solana-title weight-normal"> {{ $t('solana.stake') }} </div>
        <span class="solana-pool-switch-separator solana-title">/</span>
        <div class="solana-title weight-normal solana-pool-switch-item">
          <img src="@/assets/img/solana/sht.png" alt="sht"> SHT
        </div>

      </div>
    </div>

    <go-back url="/solana" label="solana.back_to_pools"/>

    <stake-block />

    <add-liquidity-modal v-model="add_liquidity" />
    <transaction-submitted-modal v-model="transaction_status"/>

  </div>
</template>

<script>
import GoBack from '@/components/Solana/GoBack';
import StakeBlock from '@/components/Solana/StakeBlock';
import AddLiquidityModal from '@/components/Solana/AddLiquidityModal';
import TransactionSubmittedModal from '@/components/Solana/TransactionSubmittedModal';

export default {
  name: 'SolanaStake',
  components: {
    TransactionSubmittedModal,
    AddLiquidityModal,
    StakeBlock,
    GoBack,
  },

  data() {
    return {
      items: ['Single', 'Pair'],
      type: 'Single',
      add_liquidity: false,
      transaction_status: false,
      pool: {
        image: '1.png',
        name: 'SHT/ETH',
      },
      cards: [
        {
          image: '1.png',
          name: 'SHT/ETH',
        },
        {
          image: '2.png',
          name: 'SHT',
        },
        {
          image: '3.png',
          name: 'SHT',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
